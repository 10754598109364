:root {
  --green500: #027847;
  --green600: #03654f;
  --green800: #035743;
  --green900: #023e34;
  --black700: #222;
  --shadow: #cacecece;
  --red500: #d60b00;
  --pink: #d81b60;
  --purple800: #4527a0;
  --purple600: #3949ab;
  --orange600: #f70;
  --yellow500: #ffeb3b;
  --aqua500: #026e78;
  --blue500: #006aec;
}

.camera {
  --p1: 60%;
  --p2: 48%;
  --p3: 38%;
  --c1: transparent var(--p1), gray calc(var(--p1)  + 1px) calc(var(--p1)  + 2px), lightgray calc(var(--p1)  + 5px);
  --c2: transparent var(--p2), gray calc(var(--p2)  + 1px) calc(var(--p2)  + 2px), lightgray calc(var(--p2)  + 5px);
  --c3: transparent var(--p3), gray calc(var(--p3)  + 1px) calc(var(--p3)  + 2px), lightgray calc(var(--p3)  + 5px);
  background: url("logo_ball.df602770.svg") center / cover;
  border: 1px solid;
  border-radius: 50%;
  width: 280px;
  height: 280px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.camera:before, .camera:after {
  content: "";
  background: linear-gradient(-126deg, var(--c1)) bottom left / var(--e1, 40%) var(--e2, 20%) no-repeat, linear-gradient(-162deg, var(--c1)) bottom / 100% var(--e3, 60%) no-repeat, linear-gradient(162deg, var(--c1)), linear-gradient(126deg, var(--c2)), linear-gradient(90deg, var(--c3)), linear-gradient(54deg, var(--c2)), linear-gradient(18deg, var(--c1));
  width: 100%;
  height: 200%;
  transition: all .5s linear;
  position: absolute;
  top: -50%;
  left: 50%;
}

.camera:after {
  transform-origin: 0;
  transform: rotate(180deg);
}

.camera:hover:before, .camera:hover:after {
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
}

#btnMenu {
  display: none;
}

#divOptions {
  display: flex;
}

.name {
  color: var(--black700);
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
}

.host-video {
  width: 100%;
  height: 100%;
}

@media screen and (width <= 800px) {
  #btnMenu {
    display: block;
  }

  #divOptions {
    display: none;
  }
}

/*# sourceMappingURL=index.3beeee84.css.map */
